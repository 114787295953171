import Vue from 'vue'
import VueRouter from 'vue-router'
import VideoPage from '../pages/video'
import DonationGoalPage from '../pages/donations/goal'
import DonationEventPage from '../pages/donations/event'
import DonationQRPage from '../pages/donations/qr'
import NotFoundPage from '../pages/404'
import TestPage from '../pages/test'

Vue.use(VueRouter)

const routes = [
  {
    path: '/video/:userlink',
    name: 'video',
    component: VideoPage
  },
  {
    path: '/donations/goal/:userlink',
    name: 'donation-goal',
    component: DonationGoalPage
  },
  {
    path: '/donations/event/:userlink',
    name: 'donation-event',
    component: DonationEventPage
  },
  {
    path: '/donations/qr/:userlink',
    name: 'donation-qr',
    component: DonationQRPage
  },
  {
    path: '/test',
    name: 'test',
    component: TestPage
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '*',
    name: '404',
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
