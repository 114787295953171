<template>
  <div id="test">
    <div
      v-for="(line, index) in lines"
      :key="index"
      v-html="line"
    ></div>
  </div>
</template>

<script>
// import QrCode from 'qrcode.vue'

export default {
  name: 'TestPage',
  components: {
    // QrCode
  },
  data () {
    return {
      // 04a9eb
      lines: []
    }
  },
  computed: {

  },
  mounted () {
    this.print('OBS version: ' + window.obsstudio.pluginVersion)

    window.addEventListener('obsSceneChanged', function(event) {
      this.print(JSON.stringify(event))
    })

    // window.obsstudio.getStatus(function (status) {
    //   this.print(JSON.stringify(status))
    // })

    window.obsstudio.getControlLevel(function (level) {
      console.log('level', level)
    })

    window.obsstudio.getStatus(function (status) {
      console.log('status', status)
    })

    window.obsstudio.getScenes(function (scenes) {
      console.log('scenes', scenes)
    })

    window.addEventListener('obsSceneChanged', function(event) {
      this.print('obsSceneChanged')
      console.log('obsSceneChanged', event)
    })

    this.print('finish')
  },
  methods: {
    print (x) {
      this.lines.push(x)
    }
  }
}
</script>

<style>
</style>