import Pusher from 'pusher-js'

const mixin = {
  data () {
    return {
      waitForLaunchInterval: null
    }
  },
  computed: {
    userLink () {
      return this.$route.params.userlink
    }
  },
  methods: {
    initPusher () {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        wssHost: process.env.VUE_APP_PUSHER_HOST,
        wsHost: process.env.VUE_APP_PUSHER_HOST,
        wssPath: process.env.VUE_APP_PUSHER_PATH,
        wsPath: process.env.VUE_APP_PUSHER_PATH,
        wssPort: process.env.VUE_APP_PUSHER_PORT,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        // activityTimeout: 45000,
        channelAuthorization: {
          endpoint: process.env.VUE_APP_BACKEND_HOST + '/pusher-presence-auth'
        }
      })

      this.pusher.connection.bind('failed', () => {
        document.location.reload(true)
      })

      this.addEventsLogging()

      this.subscribeChannel('common-events')
      this.channels['common-events']
        .bind('App\\Events\\Streamers\\AllLayersRefreshed', () => {
          document.location.reload(true)
        })
      
      this.subscribeChannel('presence-layer-meta.' + this.userLink)
      this.channels['presence-layer-meta.' + this.userLink]
        .bind('client-ping-is-alive', () => {
          this.channels['presence-layer-meta.' + this.userLink].trigger('client-pong-is-alive', {
            userAgent: window.navigator.userAgent
            // ip ?
          })
        })
        .bind('App\\Events\\Streamers\\LayerRefreshed', () => {
          document.location.reload(true)
        })
    },
    initTaskLaunch() {
      this.subscribeChannel('task-launch.' + this.userLink)
      this.channels['task-launch.' + this.userLink]
        .bind('App\\Events\\Tasks\\TaskExecuted', (e) => {
          if (e.type === 'web') {
            this.taskData = e
            console.log('got event', e)
          } else if (e.type === 'webWithExtensionContent') {
            let currentTimestamp = new Date().getTime()
            console.log('got event webWithExtensionContent', { currentTimestamp, executeAt: e.execute_at * 1000, diff: e.execute_at * 1000 - currentTimestamp })

            if (e.execute_at * 1000 > currentTimestamp) {
              console.log('started interval waiting for launch')
                this.waitForLaunchInterval = setInterval(() => {
                  let currentTimestamp = new Date().getTime()
                  if (currentTimestamp >= e.execute_at * 1000) {
                    console.log('launching', e)
                    this.taskData = e
                    clearInterval(this.waitForLaunchInterval)
                  }
                }, 100)

                // let diff = e.execute_at * 1000 - currentTimestamp

                // console.log('waiting for launch', diff)
                // setTimeout(() => {
                //     console.log('launching', e)
                //     this.taskData = e
                // }, diff)
            }
          }
        })
        .bind('App\\Events\\TestModeTurned', (e) => {
          this.taskData = e
        })
    },
    enableWindowSizeTracking (layerName) {
      this.channels['presence-layer-meta.' + this.userLink]
        .bind('client-track-size-req-' + layerName, () => {
          this.channels['presence-layer-meta.' + this.userLink]
            .trigger('client-track-size-resp-' + layerName, {
              userAgent: window.navigator.userAgent,
              width: window.innerWidth,
              height: window.innerHeight
            })
        })
    },
    addEventsLogging () {
      this.pusher.connection.bind("error", function (error) {
        console.error('connection error', error)
      })

      this.pusher.connection.bind("connected", function () {
        console.warn('Connected event!')
      })

      this.pusher.connection.bind("disconnected", function () {
        console.warn('Disconnected event!')
      })

      this.pusher.connection.bind("state_change", function (states) {
        // states = {previous: 'oldState', current: 'newState'}
        console.warn('Channels current state is', states.current)
      })

      this.pusher.connection.bind("failed", function (error) {
        console.error('connection_failed', error)
      })

      window.addEventListener('online', () => {
        console.log('Window is online')
      })
      window.addEventListener('offline', () => {
        console.log('Window is offline')
      })
    },
    subscribeChannel (name) {
      this.channels[name] = this.pusher.subscribe(name)
      return this.channels[name]
    }
  }
}

export default mixin
